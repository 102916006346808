.project-info {
    display: grid;
    grid-template-areas: 
        "title"
        "sidebar"
        "text";
    overflow-y: auto;
    overflow-x: hidden;
    align-content: start;

    @media only screen and (min-width: $bp-tablet) {
        grid-template-areas: 
            "title title"
            "sidebar text";
        grid-template-columns: 275px 1fr;
        overflow: unset;
    }
    
    &__title {
        grid-area: title;
        max-width: calc(100% - 5rem);
    }

    &__image {
        grid-area: image;
        width: 100%;
        max-width: 65ch;
        padding-top: 1rem;
    }

    &__text {
        grid-area: text;
        padding-top: 2rem;

        @media only screen and (min-width: $bp-tablet) {
            margin-left: 2rem;
            overflow-y: auto;
            padding-top: 0;
            padding-right: 2rem;
            max-width: calc(4rem + 65ch);
            max-height: calc(100vh - 10rem);
        }

        & .octicon-link { display: none }

        & h2, h3, h4, h5, h6 {
            padding-top: 3rem;
        }

        & ul, ol {
            padding-top: 1rem;
            margin-left: 3rem;
            max-width: calc(65ch - 3rem);
        }
    }

    &__sidebar {
        grid-area: sidebar;
        display: flex;
        flex-direction: column;

        & > * {
            padding-top: 1rem;
        }

        & > *:not(:last-child) {
            padding-bottom: 1rem;
        }

        & ul {
            list-style: none;

            & > li {
                display: inline-block;
                margin-right: 1.5rem;
                text-transform: capitalize;
            }
        }
    }

    &__languages {
        & > h2 {
            margin-bottom: 1rem;
        }

        &__bar {
            height: .75rem;
            display: flex;
            overflow: hidden;
            border-radius: 6px;
            outline: 1px solid transparent;
            gap: 2px;
            margin-bottom: .5rem;

            &__item {
                background-color: white;
            }
        }

        &__dot {
            display: inline-block;
            background-color: white;
            width: 1rem;
            height: 1rem;
            clip-path: circle(40% at 50% 50%);
            margin-right: .5rem;
        }
        
        &__name {
            color: $color-white;
        }

        &__percent {
            font-size: 1.2rem;
        }
    }

    &__tech {
        & li {
            font-size: 1.4rem;
        }
    }

    &__links {
        display: flex;
        flex-direction: column;

        & a {        
            display: flex;
            align-items: center;
            padding: 1rem;
            color: $color-secondary-1-4;
            text-transform: uppercase;
            letter-spacing: .2rem;
            border-radius: 0;
            line-height: 3rem;

            &:not(:last-child) {
                border-bottom: 1px solid $color-secondary-1-4;
            }
    
            & .fas, .fab {
                font-size: 3rem;
                margin-right: 1rem;
            }
        
            &:hover, &:focus, &:focus-visible {
                background-color: $color-primary-0;
                background-image: unset;
            }
        }
    }
}