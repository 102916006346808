@import "variables";

footer {
  padding: 2rem;
  height: min-content;
  display: flex;
  justify-content: center;
  align-self: end;
  background: $color-complement-1;
  width: 100vw;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5),
      -5px -5px 20px rgba(0, 0, 0, 0.5);
}
