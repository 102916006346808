.dialog {
    display: none;
    pointer-events: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 100;

    &.opened {
        display: grid;
    }

    &__window {
        pointer-events: auto;
        display: block;
        background-color: $color-secondary-2-0;
        padding: 1rem 2rem;
        box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5),
            -5px -5px 20px rgba(0, 0, 0, 0.5);
        position: relative;

        @media only screen and (min-width: $bp-tablet) {
            place-self: center;
        }

        &::before {
        
            @media only screen and (min-width: $bp-tablet) {
                content: '';
                display: block;
                position: absolute;
                background-color: $color-primary-3;        
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                z-index: -1;
                box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5),
                    -5px -5px 20px rgba(0, 0, 0, 0.5);
                transform: rotate(-2deg);
            }
        }
    }

    &__close {
        position: fixed;
        top: 1rem;
        right: 1rem;

        @media only screen and (min-width: $bp-tablet) {
            position: absolute;
        }
    }
}