.modal-backdrop {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #000;
    opacity: 0;
    overflow: hidden;
    z-index: 99;
    transition: opacity 3s ease-out;

    &.active {
        display: block;
        opacity: 0.6;
        transition: opacity 3s ease-out;
    }
}