@import "variables";
@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC&family=Megrim&family=Signika&display=swap');

body {
  font-family: "Signika", sans-serif;
  font-size: 1.6rem;
  color: $color-white;
}

a { text-decoration: none; }

p {
  max-width: 65ch;
  line-height: 1.6;

  &:not(:first-child) {
    padding-top: 1rem;
  }
}

p, .dialog {
  a {  
    color: $color-secondary-1-4;
    
    &:hover {
      background-image: linear-gradient(115deg, transparent 20%, $color-primary-3 20%, $color-primary-3 80%, transparent 80%);
      background-position: 0 1.04em;
      background-repeat: repeat-x;
      background-size: 10px 2px;
    }
  }
}