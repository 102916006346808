.navbar {
    &__links {
        list-style: none;
        display: none;

        @media only screen and (min-width: $bp-mobile-l) {
            display: flex;
            gap: 1rem;
        }
    }
}