// RESPONSIVE BREAKPOINTS
// Based on Chrome devtools breakpoints
$bp-mobile-s: 20em; // 320px
$bp-mobile-m: 23.4375em; // 375px
$bp-mobile-l: 26.5625em; // 425px
$bp-tablet-s: 37.5em; // 600px
$bp-tablet: 48em; // 768px
$bp-laptop: 64em; // 1024px
$bp-laptop-l: 90em; // 1440px
$bp-4k: 160em; // 2560px

// COLORS
// Generated by Paletton.com © 2002-2014
// http://paletton.com
$color-primary-0: #380F00;	// Main Primary color */
$color-primary-1: #1E0C05;
$color-primary-2: #63301D;
$color-primary-3: #952800;
$color-primary-4: #FF4500;

$color-secondary-1-0: #381F00;	// Main Secondary color (1) */
$color-secondary-1-1: #1E1305;
$color-secondary-1-2: #63451D;
$color-secondary-1-3: #955400;
$color-secondary-1-4: #FF9000;

$color-secondary-2-0: #011524;	// Main Secondary color (2) */
$color-secondary-2-1: #040D13;
$color-secondary-2-2: #152E40;
$color-secondary-2-3: #00385F;
$color-secondary-2-4: #0070C0;

$color-complement-0: #002615;	// Main Complement color */
$color-complement-1: #03150D;
$color-complement-2: #14452F;
$color-complement-3: #006738;
$color-complement-4: #00C86D;

$color-white: #ddd;