.header {
    position: sticky;
    top: 0;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;

    @media only screen and (min-width: $bp-tablet) {
        padding: 3.75rem 3rem 3.5rem 3rem;
    }

    &__background {
        position: absolute;
        width: 100%;
        height: 7rem;
        top: 0;
        left: 0;
        z-index: -1;
        background: linear-gradient($color-secondary-2-1 50%, transparent);
        opacity: 0;
        transition: opacity 300ms;
        
        @media only screen and (min-width: $bp-tablet) {
            height: 12rem;
        }
    }

    .divider {
        flex-grow: 1;
        border-bottom: 1px solid $color-white;
        margin: 0 0 0 1rem;
    }
    
    &__name {
        display: block;
        color: $color-white;
        font-family: "Megrim", cursive;
        white-space: nowrap;
        line-height: 1;
        @media only screen and (min-width: $bp-tablet) {
            margin: -3rem 0 -3rem -1rem;
        }

        h1 {
            font-size: 3rem;

            @media only screen and (min-width: $bp-tablet) {
                font-size: 6rem;
            }
        }

        .first,
        .last {
            display: inline-block;
            width: min-content;

            &::first-letter {
                font-size: 5rem;
                vertical-align: middle;
                color: $color-secondary-1-4;

                @media only screen and (min-width: $bp-tablet) {
                    font-size: 10rem;
                }
            }
        }

        .last {
            &::first-letter {
                letter-spacing: -.75rem;
            }
        }
    }
}